import choffmeisterI18next from '@choffmeister/i18next'
import i18next, { LanguageDetectorModule } from 'i18next'
import * as reactI18next from 'react-i18next'

export type AcceptedLanguages = 'en' | 'de' | 'fr'
export const acceptedLanguages = ['en', 'de', 'fr']

export function detectLanguage(pathname: string) {
  try {
    const parts = pathname.match(/\/([a-zA-Z-]*)/g)
    const language = parts instanceof Array ? parts[0].replace('/', '') : undefined
    return language && acceptedLanguages.indexOf(language) > -1 ? language : 'en'
  } catch {
    return 'en'
  }
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    if (typeof window !== `undefined`) {
      return detectLanguage(window.location.pathname)
    } else {
      return 'en'
    }
  },
  init: () => {},
  cacheUserLanguage: () => {},
}

i18next
  .use(languageDetector)
  .use(reactI18next.initReactI18next)
  .use(choffmeisterI18next.init)
  .init(
    {
      fallbackLng: 'en',
      resources: {},
      interpolation: {
        escapeValue: false,
      },
      react: {
        nsMode: 'default',
      },
      returnNull: false,
      debug: process.env.NODE_ENV !== 'production',
    },
    (err) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
  )

export { i18next, languageDetector }
